.modal-wrap .modal-content {
  background-image: url(../../../assets/congratulation/image3.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
 
}
.modal-wrap .modal-dialog{
    height: 80vh;
    display: flex;
    align-items: center;
}
.modal-bg-color {
  background-color: #0000;
  border: none !important;
  display: flex;
  justify-content: center;
}
.congrate-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.check-icon-wrap {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #FFC727;
  display: flex;
  justify-content: center;
  align-items: center;
}
.check-icon {
  font-size: 50px;
}
.congrate-hd {
  color: white;
  font-size: 30px;
  margin: 0px;
}
.congrate-text {
  color: white;
  font-size: 20px;
  margin: 0px;
}
.ok-btn {
  background-color: #FFC727;
  font-size: 20px;
  padding: 0px 60px 0px 60px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: white;
  border: none;
}
