.otp-main-container {
    min-height: 100vh;
    background-image: linear-gradient(180deg, rgb(93, 19, 147), rgb(59, 9, 124));
    position: relative;
    overflow: hidden;
}

.otp-main-container .main-wrap {
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.otp-main-container .main-wrap .sub-wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 50px;
    min-height: 90vh;
}

.otp-form {
    width: 35%;
    background-color: #fdc21f;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 22px;
    padding: 20px 0px 20px 0px;
}

.otp-heading h2 {
    color: #581690;
    font-family: system-ui;
}

.otp-image {
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-otp-form {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.crediantal-fields {
    background-color: white;
    border-radius: 15px;
    border: none;
    outline: none;
    width: 70%;
    height: 50px;
}

.subline-wrap .subline {
    margin: 0px;
    color: black;
    font-size: 18px;
    font-weight: 700;
}

.Btn-wrap {
    margin-top: 20px;
}

.Btn-wrap .Btn {
    border-radius: 100px;
    background-color: #581690;
    padding: 6px;
    color: white;
    min-width: 124px;
}

.progress-container {
    width: 200px !important;
    margin-top: 30px;
}

.back-link-wrap {
    width: 70%;
    display: flex;
    justify-content: end;
    align-items: end;
}

@media only screen and (max-width: 1100px) {
    .otp-main-container .main-wrap {
        justify-content: center;
    }

    .crediantal-fields {
        width: 80%;
    }
}

@media (max-width:1000px) {
    .otp-image {
        display: none;
    }

    .otp-form {
        width: 450px;
        margin-bottom: 30px;
    }

    .otp-form {
        margin: 0px 20px 0px 20px;
    }
}

@media only screen and (max-width: 425px) {
    .crediantal-fields {
        width: 90% !important;
    }

    .otp-form {
        margin: 0px 30px 0px 30px;
    }

    .otp-heading h2 {
        font-size: 30px;
        margin: 0px 15px 0px 15px;
    }

    .subline .redirect {
        font-size: 15px;
    }

    .subline-wrap .subline {
        font-size: 15px !important;
    }
}

.animated-image {
    animation: moveUpDown 5s infinite;
}

.blurred-shadow {
    filter: drop-shadow(5px 5px 20px white) drop-shadow(200px 20px 200px #581690);
}

@keyframes moveUpDown {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(20px);
    }
}