.our-founder-container {
  width: 100%;
  height: max-content;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.our-founder-wrap {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* justify-content: space-between; */
  margin-top: 50px;
}
.our-founder-content-wrap {
  width: 50%;
}
.our-founder-content-hd {
  text-transform: capitalize;
  font-size: 3.2vw;
  font-weight: 900;
  color: #25234d;
  line-height: 4.5vw;
}
.our-founder-text {
  color: rgba(128, 128, 128, 0.792);
  font-size: 1.3vw;
  line-height: 2.5vw;
  margin-top: 60px;
}
.our-founder-img-container {
  width: 45%;
}
.our-founder-img {
  width: 100%;
}
.founder-name-container {
  width: 90%;
}
.founder-name {
  color: #8b76f6;
  font-size: 1.5vw;
  font-weight: 600;
  line-height: 2vw;
  margin: 0px;
}
.founder-name-res-container {
  display: none;
}
@media (max-width: 1024px) {
  .our-founder-container{
    min-height: 600px;
  }
  .our-founder-content-hd {
    font-size: 5vw;
  }
  .our-founder-text {
    font-size: 2.2vw;
  }
  .founder-name{
    font-size: 3vw;
    line-height: 25px;
  }
}
@media (max-width: 850px) {
  .our-founder-container {
    height: 100%;
    min-height: 100%;
  }
  .our-founder-wrap {
    min-height: 100%;
    flex-direction: column;
    align-items: center;
  }
  .our-founder-content-wrap {
    margin-bottom: 50px;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  .our-founder-img-main-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .our-founder-img-container {
    width: 70%;
  }
  .founder-name-res-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .founder-name-container {
    display: none;
  }
  .our-founder-content-hd {
    font-size: 6vw;
    line-height: 30px;
  }
  .our-founder-text {
    font-size: 2.5vw;
    line-height: 30px;
    margin-top: 0px;
  }
  .founder-name{
    font-size: 3.5vw;
  }
}
@media (max-width: 550px) {
  .our-founder-content-hd {
    font-size: 6vw;
    line-height: 30px;
  }
  .our-founder-text {
    font-size: 3.5vw;
    line-height: 25px;
  }
  .founder-name{
    font-size: 4vw;
  }
}
