.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .not-found-title {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #333; /* Adjust the color as needed */
  }
  
  .not-found-text {
    font-size: 1rem;
    color: #555; /* Adjust the color as needed */
  }
  