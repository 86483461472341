/* multi lingual css */

.multi-lingual-main-container {
  background-image: url(../../../assets/muti-lingual/BG.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow-y: hidden;
  padding: 50px 0px 50px 0px;
}
.multi-lingual-wrap {
  height: 100%;
  min-height: 100vh;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.multi-lingual-img-wrap {
  background-image: url(../../../assets/muti-lingual/circle.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* background-position: 50% 0%; */
  width: 45%;
  margin-bottom: 100px;
}
.multi-lingual-img {
  width: 100%;
  position: relative;
  top: 100px;
}
.multi-lingual-content-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 10px 0px 10px;
}
.multi-lingual-hd {
  text-transform: capitalize;
  color: white;
  font-size: 4vw;
  font-weight: 900;
  line-height: 4vw;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.multi-lingual-text {
  color: rgba(255, 255, 255, 0.617);
  font-size: 1.5vw;
  font-weight: lighter;
  line-height: 2.5vw;
}

@media (max-width: 1024px) {
  .multi-lingual-wrap{
    min-height: 600px;
  }
  .multi-lingual-img-wrap {
    width: 50%;
  }
  .multi-lingual-content-container {
    margin-left: 20px;
  }
  .multi-lingual-hd {
    font-size: 3.7vw;
  }
  .multi-lingual-text {
    font-size: 1.7vw;
  }
}

@media (max-width: 850px) {
  .multi-lingual-main-container {
    background-image: none;
    background-color: #2d0572;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .multi-lingual-wrap {
    width: 90%;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .multi-lingual-img-wrap {
    padding-bottom: 10px;
    width: 80%;
  }
  .multi-lingual-content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 10px 0px 10px;
    text-align: center;
    margin: 50px 0px 50px 0px;
  }
  .multi-lingual-hd {
    color: white;
    font-size: 5vw;
    font-weight: 800;
    line-height: 60px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }
  .multi-lingual-text {
    color: rgba(255, 255, 255, 0.617);
    font-size: 2.4vw;
    font-weight: lighter;
    line-height: 30px;
  }
}

@media (max-width: 550px) {
  .multi-lingual-img-wrap {
    width: 100%;
  }
  .multi-lingual-hd {
    font-size: 6.5vw;
    line-height: 30px;
  }
  .multi-lingual-text {
    font-size: 3.5vw;
    line-height: 20px;
  }
}
@media (max-width: 430px) {
  .multi-lingual-img {
top: 80px;
  }
}
