.dashboard-navbar-section {
  min-height: 10%;
  background-color: #581690;
  padding-top: 5px;
}

.nav-bars {
  background-color: transparent;
}

.navbar-wrapper-dashboard {
  padding-top: 10px;
  padding-bottom: 10px;
}

.dashboard-search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #975269;
  width: 60px;
  height: 60px;
  border-radius: 50px;
}

.dashboard-header-user-profile-click {
  padding: 0px !important;
}

.dashboard-search-wrapper {
  background-color: #fdc121;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-text {
  font-size: 30px;
  padding-left: 30px;
  color: white;
  font-weight: 900;
}

.navbar-text:hover {
  color: white;
  cursor: pointer;
}

.search-input {
  display: block;
  height: 30px;
  width: 300px;
  background-color: #2e0f74;
  border: none;
  border-radius: 20px;

  margin-left: 10px;
}

.search-input::placeholder {
  padding: 20px;
}

.jamal-profile-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  /* width: 40%; */
}

.jamal-profile {
  width: 60px !important;
  height: 60px !important;
  margin-right: 0px;
}

.nav-bar-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* #header-user-profile-menu{
   visibility: visible;
   opacity: 1;
} */

.jamal-profile-container .username {
  margin: 0px;
  color: white;
  font-size: 16px !important;
  margin-left: 10px;
  font-family: sans-serif;
  letter-spacing: 1px;
}

.jamal-profile-container-responsive .username {
  margin: 0px;
  color: white;
  font-size: 16px !important;
  margin-left: 10px;
  font-family: sans-serif;
  letter-spacing: 1px;
}

.jamal-profile-container-responsive img {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  margin-right: 6px;
}

.jamal-profile-container-responsive {
  display: none;
  /* display: flex; */
}

.profile-menu-header {
  background-color: #333333e0 !important;
}

#header-menu-data {
  font-size: 15px !important;
  color: white !important;
}

#header-menu-data:hover {
  background-color: #333333ef !important;
  color: #fdc121 !important;
}

.responsive-navbar {
  display: none;
}

.navbar-color {
  background-color: #581690 !important;
}

.responsive-navbar-wrap {
  display: none;
}
.logo{
  width: 200px;
}
.color{
  border-color: white !important;
  padding-bottom: 6px;
  color: white !important;
}

@media (max-width: 992px) {
  .dashboard-search-container {
    display: none;
  }

  .search-input {
    display: none;
  }

  #serach-icon {
    display: flex !important;
  }

  #search-input {
    display: block !important;
    width: 275px !important;
  }

  .search-wrapper {
    display: flex;
    /* justify-content: center; */
  }

  #offcanvasNavbarLabel-expand-lg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 991px) {
  .notification-icon {
    display: none;
  }

  .jamal-profile-container {
    display: none;
  }

  .responsive-navbar {
    display: flex;
    flex-direction: column;
    width: 100%;
    line-height: 40px;
    border-radius: 10px;
  }

  .responsive-navbar .responsive-navbar-link {
    color: white !important;
    font-size: 20px;
    margin-bottom: 20px;
    padding-left: 10px;
  }

  .responsive-navbar .responsive-navbar-link:hover {
    color: #fdc121 !important;
    border-radius: 10px;
  }

  .jamal-profile-container-responsive {
    /* display: none; */
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .profile-menu-header {
    display: none;
  }
}

.search-bar {
  background-color: #34006e;
  border-left: 1px solid gray;
  border-bottom: none;
  border-right: none;
  border-top: none;
  outline: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-left: 10px;
  color: rgb(255, 255, 255);
  height: 40px;
  width: 100%;
}

.search-bar::placeholder {
  color: gray;
}

.search-icon {
  color: rgb(255, 255, 255);
  font-size: 28px;
  margin: 0px;
  cursor: pointer;
}

.search-bar-wrap {
  display: flex;
}

.search-icon-wrap {
  background-color: #34006e;
  height: 40px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.word-check-modal-body {
  border: 20px solid #ffbd25 !important;
  border-radius: 31% 31% 31% 31% / 140% 140% 140% 140% !important;
  height: 420px;
  background-color: #ffe4c7;
  position: relative !important;
  padding: 0px !important;
}

.parent-modal-dashboard > .modal-dialog > .modal-content {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  max-width: 350px !important;
  min-width: 350px !important;
}

.parent-modal-dashboard > .modal-dialog {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  /* align-items: flex-end !important; */
  height: 90vh;
  /* max-width: 350px !important;
  min-width: 350px !important; */
}

.word-check-genie {
  width: 280px;
  height: 280px;
}

.modal-body-main-container {
  border-radius: 16% 16% 16% 16% / 140% 140% 140% 140% !important;
  border-top: 6px solid #43282d;
  border-right: 4px solid #43282d;
  border-left: 4px solid #43282d;
  border-bottom: 2px solid #43282d;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 2px 0px 28px 0px #43282d inset;
  -webkit-box-shadow: 2px 0px 18px 0px #43282d inset;
  -moz-box-shadow: 2px 0px 28px 0px #43282d inset;
  padding: 0px;
}
.word-check-genie-container {
  position: absolute;
  top: -180px;
}

.meaning-language-btn-wrap {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  height: 130px;
  width: 100%;
  z-index: 2;
}
.meaning-btn {
  background-color: #ffc107;
  color: #554328;
  border-radius: 7px;
  width: 120px;
  height: 40px;
  font-weight: bolder;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
}
.meaning-btn.font-weight-bold {
  color: white !important;
}
.language-btn.font-weight-bold {
  color: white !important;
}
.language-btn {
  background-color: #ffc107;
  color: #554328;
  border-radius: 7px;
  width: 120px;
  height: 40px;
  font-weight: bolder;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
}

.all-languages-btn {
  background-color: #686953;
  color: #ffe4c7;
  border-radius: 7px;
  width: 120px;
  height: 40px;
  font-weight: bolder;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  margin-bottom: 10px;
}
.languages-btn-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
/* .select-language-text-wrap{
   width: 100%;
  display: flex;
  justify-content: center;
} */
.select-language-text {
  font-weight: bold;
  padding: 0px 17px 0px 17px;
  font-style: oblique;
  color: black;
  margin: 5px 0px 15px 0px;
  line-height: 130%;
}
/* .parent-modal-dashboard{
   display: flex !important;
   flex-direction: column !important;
   justify-content: center !important;
   align-items: center !important;
   width: 100% !important;
} */

/* .modal-container{
   background-color: unset;
} */

.dictionary-text {
  width: 100%;
  max-height: 215px;
  overflow: auto;
  color: black;
  margin-right: 20px;
  padding: 10px 10px 0px 30px;
  font-style: italic;
}

.dictionary-text::-webkit-scrollbar {
  width: 10px;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dictionary-text::-webkit-scrollbar-track {
  width: 18px;
  border-radius: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.dictionary-text::-webkit-scrollbar-thumb {
  margin-top: 30px;
  margin-bottom: 30px;
}

.dictionary-text::-webkit-scrollbar-thumb {
  background-color: #300c4d;
  border: 1px solid #300c4d;
  border-radius: 20px;
  width: 5px;
}

.loader.translation {
  border-top: 8px solid #000000;
}
.loader-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cancel-btn-wrap {
  position: absolute;
  top: -45px;
  right: -20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #581690;
  border-radius: 100%;
  cursor: pointer;
}
.cancel-btn {
  color: white;
  width: 40px;
  height: 40px;
  margin: 0px;
  padding: 0px;
}
.navbar-text-search-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.navbar-text-search-container-responsive {
  display: none;
}
.navbar-text-container {
  display: flex;
  justify-content: flex-start;
}
.search-bar-container {
  width: 50%;
}
.menu-container {
  display: flex;
  justify-content: flex-end;
}
.book-detail-responsive-text {
  padding-left: 0px !important;
  padding-right: 10px !important;
}
@media (max-width: 780px) {
  .navbar-text-container {
    display: none;
  }
  .search-bar-container {
    width: 80%;
    padding: 10px 0px 10px 0px;
  }
}

/* 
@media (max-width: 360px) {
  .parent-modal-dashboard > .modal-dialog > .modal-content {
    max-width: 300px !important;
    min-width: 300px !important;
  }
  .all-languages-btn {
    width: 80px;
    height: 30px;
    font-size: 15px;
  }
  .meaning-btn {
    width: 80px;
    height: 30px;
    font-size: 15px;
  }
  .language-btn {
    width: 80px;
    height: 30px;
    font-size: 15px;
  }
  .word-check-genie {
    width: 260px;
    height: 260px;
  }
} */
.main-dashboard-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
