.main-div {
    width: 100%;
    border-radius: 10px;

}

.btn {
    /* background-color: #4CAF50; */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 10px;
    background-color: #800080;
    border: 1px solid black;
}

.password-container {
    line-height: 40px;
}

.password-field {
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
    margin-top: 10px;
    border-radius: 10px;
    border: none;
    outline: none;
    box-shadow: 0px 9px 16px -5px rgba(0, 0, 0, 1);
    -webkit-box-shadow: 0px 7px 10px -10px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 9px 16px -5px rgba(0, 0, 0, 1);
    background-color: #ffffff;
}
input::placeholder{
    color: black;
}

.headings {
    margin: 0px;
    color: white;
    padding-top: 20px; /* Adjust the value as needed */
}

.email-sugest {
    margin-top: 1px;
    margin-bottom: 0.5rem;
    color: #FEBF0B;
}

/* .Modal {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.btn-cancel{
    background-color: white;
    color: black;
}

.btn-cancel:hover{
    background-color: white;
    color: black;
}

.btn-continue:hover{
    background-color: #FEBF0B;
    color: white;
}
.forgot-link{
    color: #FEBF0B;
}
.reset-password-wrap{
    display: flex;
    flex-direction: column;
}
.input-names{
    margin: 0px;
}
.reset-password-input{
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
    margin-top: 10px;
    border-radius: 10px;
    border: none;
    outline: none;
    box-shadow: 0px 9px 16px -5px rgba(0, 0, 0, 1);
    -webkit-box-shadow: 0px 7px 10px -10px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 9px 16px -5px rgba(0, 0, 0, 1);
    background-color: #ffffff;
}
.disabled{
    background-color: rgb(255, 255, 255) !important;
    color: black !important;
}