.about-us-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  height: max-content;
  align-items: center;
  padding: 50px 0px 50px 0px;
}
.about-us-container {
  width: 90%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about-us-content-wrap {
  width: 45%;
}
.about-us-content-hd {
  font-size: 4vw;
  font-weight: 900;
  color: #25234d;
  /* width: 300px; */
  line-height: 4vw;
}
.about-us-text {
  color: gray;
  font-size: 1.7vw;
  line-height: 2.5vw;
}
.about-us-img-container {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ipad-img-container {
  width: 100%;
  background-image: url(../../../assets/third\ section/Circle.png);
  background-size: 90% 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}
.ipad-img {
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .about-us-container{
    min-height: 600px;
  }
  .about-us-img-container {
    width: 50%;
  }
  .about-us-content-hd {
    font-size: 3.7vw;
  }
  .about-us-text {
    font-size: 1.7vw;
  }
}
@media (max-width: 850px) {
  .about-us-main-container {
    height: 100%;
  }
  .about-us-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
  }
  .about-us-content-wrap {
    margin-bottom: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 0px;
  }
  .about-us-img-container {
    width: 70%;
  }
  .about-us-content-hd {
    font-size: 5vw;
  }
  .about-us-text {
    font-size: 2.4vw;
  }
}
@media (max-width: 550px) {
  .about-us-img-container {
    width: 80%;
  }
  .about-us-container{
    min-height: max-content;
  }
  .about-us-content-hd {
    font-size: 6vw;
    line-height: 30px;
  }
  .about-us-text {
    font-size: 3vw;
    line-height: 20px;
  }
}
