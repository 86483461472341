@font-face {
    font-family: dimbo;
    src: url(../../font/Dimbo\ Regular.ttf);
  }
.header-logo {
    width: 80px;
    margin-right: 10px;
    /* width: 40%; */
  }
  .logo-hd-wrap {
    display: flex;
    align-items: center;
  }
  .nav-bar-logo {
    width: 180px;
  }
  .ai-powered-hd {
    line-height: 1px;
    color: white;
    font-family: circular;
    margin: 0px;
    font-size: 10px;
    letter-spacing: 0px;
  }
  .logo-text {
    font-family: circular;
    letter-spacing: 0px;
    color: white;
    font-size: 20px;
    margin: 0px;
  }