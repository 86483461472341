/* crousel css */

.carousel-main-container {
  background-image: url(../../../assets/what\ reader/White_BG.png);
  height: max-content;
  min-height: 100vh;
  width: 100%;
  background-size: 100% 100%;
  box-shadow: 0px 48px 100px -5px rgba(0, 0, 0, 0.5),
    0px -14px 44px -5px rgba(0, 0, 0, 0.5);
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.carousel-hd-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}
.carosuel-hd {
  text-transform: capitalize;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 3.5vw;
  color: #1b3e5c;
  font-weight: 900;
  text-align: center;
  padding: 0px 10px 0px 10px;
}
.categories-name-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
.category-name {
  font-size: 2vw;
  padding: 1.5vw;
  border: none;
  outline: none;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.855);
  font-weight: 600;
  margin-bottom: 10px;
  transition: background-color 0.5s, border-radius 0.3s, box-shadow 0.3s;
}
.category-name:hover {
  background-color: #ffc727;
  border-radius: 10px;
  cursor: pointer;
}
.category-name:focus,
.category-name.selected {
  background-color: #ffc727;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.see-category-btn-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}
.see-category-btn {
  padding: 0px 2.5vw 0px 2.5vw;
  height: 3vw;
  background-color: #ffc727;
  border-radius: 10vw;
  border-left: none;
  border-top: none;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  font-size: 1.5vw;
  font-weight: bold;
  z-index: 3;
  /* margin-top: 30px; */
}
.see-category-btn:hover {
  cursor: pointer !important;
  color: gray !important;
}
.courousel-main-wrap {
  position: relative;
  width: 75% !important;
  margin: 0px auto 0px auto;
  padding-top: 100px;
}
.category-book-title{
  text-align: center;
}
.category-arrow-wrap{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffc727;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute;
  top: -50px;
  right: 0px; */
}
.category-arrow{
  font-size: 25px;
  color: #5A1291;
}
.category-right-arrow{
  position: absolute;
  top: -50px;
  right: 0px;
}
.category-left-arrow{
  position: absolute;
  top: -50px;
  right: 70px;
}
.disable{
  background-color: #ffc52775;
}

@media (max-width: 1024px) {
  .crosuel-main-container {
    min-height: 600px;
  }
  .carosuel-hd {
    font-size: 3.7vw;
  }
  .category-name {
    font-size: 1.7vw;
  }
  .see-category-btn {
    font-size: 2vw;
    padding: 0px 3vw 0px 3vw;
    height: 3.7vw;
  }
}

@media (max-width: 850px) {
  .carousel-main-container {
    margin-top: 0px;
    min-height: 100%;
    padding-bottom: 40px;
  }
  .carosuel-hd {
    font-size: 5vw;
  }
  .category-name {
    font-size: 2.4vw;
  }
  .see-category-btn {
    width: 24vw;
    height: 5.5vw;
    font-size: 2.5vw;
  }
}
@media (max-width: 550px) {
  .carosuel-hd {
    font-size: 6vw;
  }
  .category-name {
    font-size: 4vw;
  }
  .see-category-btn {
    width: 28vw;
    height: 6vw;
    font-size: 3vw;
  }
}
