.login-main-container {
    min-height: 100vh;
    background-image: linear-gradient(180deg, rgb(93, 19, 147), rgb(59, 9, 124));
    position: relative;
    overflow: hidden;
}

.login-main-container .main-wrap {
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.login-main-container .main-wrap .sub-wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 90vh;
    padding-bottom: 50px;
}

.login-form {
    width: 35%;
    background-color: #fdc21f;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 22px;
    padding: 20px 0px 20px 0px;
    z-index: 1;
}

.login-heading h2 {
    color: #581690;
    font-family: system-ui;
    margin-top: 30px;
}

.login-image {
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.custom-login-form {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.crediantal-fields {
    background-color: white;
    border-radius: 15px;
    border: none;
    outline: none;
    width: 70%;
    height: 50px;
}

.subline-wrap .subline {
    margin: 0px;
    color: black;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
}

.Btn-wrap {
    margin-top: 20px;
}

.Btn-wrap .Btn {
    border-radius: 100px;
    background-color: #581690;
    padding: 6px;
    color: white;
    min-width: 124px;
    z-index: 1;
}

.login-main-container .redirect {
    color: #581690;
    cursor: pointer;
}

.progress-container {
    width: 200px !important;
    margin-top: 30px;
}

.animated-image {
    z-index: 1;
    animation: moveUpDown 5s infinite;
}

.blurred-shadow {
    filter: drop-shadow(5px 5px 20px white) drop-shadow(200px 20px 200px #581690);
}

.light-donut-login {
    width: 100px;
    position: absolute;
    right: 0px;
    top: 60px;
    z-index: 1;
}

.dark-donut-login {
    position: absolute;
    width: 200px;
    height: 200px;
    left: -50px;
    bottom: -40px;
    right: 0px;
}

@media only screen and (max-width: 1000px) {
    .login-image {
        display: none;
    }

    .crediantal-fields {
        width: 90% !important;
    }

    .login-form {
        width: 450px;
        margin-bottom: 30px;
        margin: 0px 20px 0px 20px;

    }
}

@media only screen and (max-width: 425px) {
    .crediantal-fields {
        width: 90% !important;
    }

    .login-form {
        margin: 0px 30px 0px 30px;
    }

    .login-heading h2 {
        font-size: 35px;
    }

    .subline .redirect {
        font-size: 15px;
    }

    .subline-wrap .subline {
        font-size: 15px !important;
    }
}

@media only screen and (max-width: 1100px) {
    .login-main-container .main-wrap {
        justify-content: center;
    }
}

@media (max-width: 540px) {
    .dark-donut-login {
        position: absolute;
    width: 210px;
    height: 200px;
    left: -130px;
    bottom: 0px;
    right: 0px;
}
.light-donut-login {
    width: 100px;
    position: absolute;
    right: 0px;
    top: 88px;
    z-index: 1;
}
    }

@keyframes moveUpDown {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(20px);
    }
}