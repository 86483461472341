.navbar-section {
    min-height: 10vh;
    background-color: #581690;

}
.nav-bars{
    background-color: transparent;
 }
 
 .navbar-wrapper{
     padding: 0px 50px 0px 50px;
 }
 .navbar-text {
     font-size: 40px;
     padding-left: 30px;
     color: white;
     font-weight: 900;
 }
 .navbar-text:hover {
     color: white;
 }
 @media(max-width: 440px){
    .navbar-text{
        padding-left: 0px;
    }
   
 }
 @media(max-width: 460px){
    .navbar-wrapper{
        padding: 20px 30px 10px 30px;
    }
   
 }

 @media(max-width: 404px){
    .navbar-toggler{
        margin-left: 30px;
    }
 }