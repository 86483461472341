.footer-wrap {
  width: 100%;
  height: 100%;
  background-color: #ffc727;
  margin-top: 125px;
}
.testimonial-container {
  background-image: url(../../../assets/testimonal/bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  min-height: 100vh;
  position: relative;
}
.testimonial-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.testimonial-yellow-donut {
  width: 150px;
  position: absolute;
  top: -70px;
  left: -60px;
}
.bg-imgs-and-content-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.review-people-bg-img {
  position: absolute;
  z-index: 1;
  width: 100%;
}
.white-dotted-bg-img {
  position: absolute;
  width: 60%;
  height: 56vh;
}
.testimonial-hd-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 20px;
}
.testimonial-content-hd {
  text-transform: capitalize;
  color: white;
  font-size: 3.8vw;
  font-weight: 900;
  margin: 0px;
  line-height: 30px;
}
.testimonial-card-main-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  z-index: 1;
  position: relative;
  width: 60%;
  overflow: hidden;
}

.testimonial-card-container {
  min-width: 100%;
  height: 80vh;
  background-image: url(../../../assets/testimonal/card-bg2.png);
  background-size: 90% 98%;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  transition: opacity 0.5s ease-in-out; 
}
.testimonial-card-wrap {
  width: 100%;
  height: 100%;
  background-image: url(../../../assets/testimonal/card-bg.png);
  background-size: 95% 95%;
  background-repeat: no-repeat;
  background-position: 50% 0%;
}
.testimonial-text-container {
  width: 100%;
  height: 100%;
  background-image: url(../../../assets/testimonal/card.png);
  background-size: 100% 90%;
  background-repeat: no-repeat;
  background-position: 50% 0%;
}
.comma-bg-img {
  background-image: url(../../../assets/testimonal/comma.png);
  background-size: 28% 40%;
  background-position: 50% 25%;
  background-repeat: no-repeat;
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.testimonial-text-wrap {
  width: 70%;
  text-align: center;
}
.testimonial-text {
  color: rgba(128, 128, 128, 0.938);
  font-weight: 600;
  margin-top: 75px;
  font-size: 20px;
  transition: transform 0.5s ease-in-out ;
  transform: translateX(0%);
}
.review-person-name {
  color: #6427de;
  font-weight: 900;
  font-size: 20px;
  margin: 0px;
  line-height: 20px;
  text-align: center;
}
.star-icons {
  color: #ffc727;
  margin: 0px;
  font-size: 18px;
  margin-right: 5px;
}
.arrow-icons-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 20px;
}
.left-arrow-icon-wrap {
  border: 1px solid black;
  outline: none;
  margin-right: 20px;
  background-color: transparent;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.left-arrow-icon-wrap:active {
  border: none;
  background-color: #00dadc;
  color: white;
}
.right-arrow-icon-wrap {
  border: 1px solid black;
  outline: none;
  background-color: transparent;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.right-arrow-icon-wrap:active {
  border: none;
  background-color: #00dadc;
  color: white;
}
.footer-content-container {
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.footer-links-wrap {
  width: 60%;
  display: flex;
  gap: 1.8rem;
  justify-content: center;
}
.footer-content-hd {
  margin-top: 10px;
  margin-left: 10px;
  font-size: 30px;
  color: rgba(0, 0, 0, 0.68);
  font-weight: 900;
}
.footer-link {
  color: rgba(0, 0, 0, 0.58);
  margin: 0px;
  cursor: pointer;
}
.social-media-all-icon-container {
  display: flex;
  justify-content: flex-end;
  gap: 0.9rem;
  width: 20%;
}
.facebook-icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #5c1392;
}
.facebook-icon-wrap:hover {
  background-color: blueviolet;
  cursor: pointer;
}
.facebook-icon {
  margin: 0px;
  padding: 0px;
  color: white;
  font-size: 17px;
}
.social-media-icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #a496af54;
}
.social-media-icon-wrap:hover {
  background-color: #a496af99;
  cursor: pointer;
}
.social-media-icon {
  font-size: 17px;
  color: white;
  margin: 0px;
  padding: 0px;
}
.copyright-text-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.copyright-text {
  color: rgba(0, 0, 0, 0.394);
  margin: 0px;
}

.footer-content-hd-wrap {
  width: 20%;
}
/* .testimonial-text.animation{
  transform: translateX(100%);
}
.testimonial-text.animation-left{
  transform: translateX(-100%);
} */

/* media queries */
@media (max-width: 900px) {
  .footer-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .footer-content-hd-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .footer-links-wrap {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
  }
  .social-media-all-icon-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .social-media-icon-wrap {
    margin-left: 10px;
  }
  .testimonial-content-hd {
    font-size: 4.8vw;
  }
  .comma-bg-img{
    background-size: 30% 30%;
  }

}

@media (max-width: 850px) {
  .testimonial-content-hd {
    text-align: center;
    line-height: 40px;
  }
  .review-people-bg-img{
    display: none;
  }
  .testimonial-card-main-container{
    width: 100%;
  }
  .white-dotted-bg-img{
    width: 100%;
  }
  .review-people-bg-img{
    display: none;
  }
}
@media (max-width: 550px) {
  .testimonial-card-container{
    height: 500px;
  }
  .white-dotted-bg-img{
    display: none;
  }
  .white-dotted-bg-img {
    width: 100%;
  }
  .testimonial-text {
    font-size: 4vw;
  }
  .copyright-text-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  .testimonial-content-hd {
    font-size: 6.8vw;
  }
  .comma-bg-img{ 
    background-size: 28% 30%;
  }
  .testimonial-container{
    min-height: max-content;
  }
}
