.login-main-container {
    min-height: 100vh;
    background-image: linear-gradient(180deg, rgb(93, 19, 147), rgb(59, 9, 124));
    position: relative;

}

.login-main-container .main-wrap {
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.login-main-container .main-wrap .sub-wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 90vh;
    padding-bottom: 50px;

}

.add-profile-form {
    width: 35%;
    background-color: #fdc21f;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 22px;
    padding: 20px 0px 20px 0px;
}

.login-heading h2 {
    color: #581690;
    font-family: system-ui;
    margin-top: 30px;
    text-align: center;
}

.add-profile-image {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    gap: 25px;

}

.custom-add-profile-form {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 10px;

}

.crediantal-fields {
    background-color: white;
    border-radius: 15px;
    border: none;
    outline: none;
    width: 70%;
    height: 50px;

}

.subline-wrap .subline {
    margin: 0px;
    color: black;
    font-size: 18px;
    font-weight: 700;
}

.Btn-wrap {
    margin-top: 20px;
}

.Btn-wrap .Btn {
    border-radius: 100px;
    background-color: #581690;
    padding: 6px;
    color: white;
    min-width: 124px;
}

.login-main-container .redirect {
    color: #581690;
    cursor: pointer;

}

.progress-container {
    width: 200px !important;
    margin-top: 30px;
}

#select-gender {
    display: flex;
    align-items: center;
    justify-content: center;

}

#select-gender:hover {
    cursor: pointer;
}


.add-profile-select-gender {
    right: unset !important;
    opacity: unset !important;
    visibility: visible !important;
    width: 100% !important;
    margin-top: 62px !important;
}

.avatar-hd {
    color: white;
    font-family: system-ui;
    text-align: center;
    font-size: 35px;
}

.add-profile-image-responsive {
    display: none;
}
.date-input{
    color: black !important ;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.date-input:hover{
    cursor: pointer;
}
@media only screen and (max-width: 1345px) {
    .add-profile-image {
        width: 50%;
    }
}

.add-profile-menu-wrap {
    width: 100% !important;
    display: flex;
    justify-content: center;
}


@media only screen and (max-width: 1100px) {
    .login-main-container .main-wrap {
        justify-content: center;
    }

    .add-profile-form {
        width: 80%;
        margin-bottom: 30px;
    }

    .add-profile-image {
        display: none;
    }

    .add-profile-menu-wrap {
        width: 80%;

    }

    .add-profile-image-responsive {
        display: block;
    }

    .avatar-hd {
        color: #581690;
        font-family: system-ui;
        text-align: center;
        font-size: 35px;
    }

    .all-avartar-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

}

.add-profile-select-gender{
    width: 70% !important;

}


.add-profile-select-gender ul li {
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    color: #fdc21f;
    padding: 10px 20px;
    font-size: 13px;
}

.add-profile-select-gender ul li:hover {
    background: rgba(0, 0, 0, 0.04);
    cursor: pointer;
}

@media(max-width:630px) {

    .all-avartar-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    
    }
}

.animated-image {
    animation: moveUpDown 5s infinite;
}

.top3-img-wrap {
    display: flex;
    gap: 15px;
}

.add-profile-img {
    width: 150px;
    cursor: pointer;
    transition: transform 1s ease, opacity 2s ease;

}


.add-profile-img:hover {
    transform: scale(1.05);

}
@media (max-width:1000px){
    .add-profile-select-gender{
        display: block;
        width: 90% !important;
    }
}

@keyframes moveUpDown {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(20px);
    }
}

.blurred-shadow {
    filter: drop-shadow(5px 5px 20px white) drop-shadow(200px 20px 200px #581690);
}

.top3-img-wrap .selected {
    border: 4px solid white;
    border-radius: 100px;
}

.all-avartar-wrap .selected {
    border: 4px solid white;
    border-radius: 100px;
}