.music-vloume:hover label {
  display: block;
}

.icon-volume-wrap {
  position: absolute;
  top: 5px;
}

.music-volume-container {
  visibility: hidden;
}

.volume-icon {
  font-size: 25px;
  color: orangered;
  margin-top: 10px;
  margin-left: 10px;
}

.icon-wrap {
  z-index: 4;
}

.icon-volume-wrap:hover .music-volume-container {
  visibility: visible;
}

#volume {
  transform: rotate(-90deg);
  position: relative;
  top: -85px;
  left: -20px;
  width: 90px;
}

.icon-wrap {
  margin: 0px;
}

.volume-main-wrap {
  position: relative;
  margin-right: 50px;
}

.book-main-wraps {
  background: url(https://fg.backend.fictiongenie.com/resources/static/assets/uploads/booksImage/The%20Clever%20Foxs%20Secret.jpg);
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  height: 100%;
  width: 100%;
  display: flex;
  width: 100%;
  height: 100%;
}

#sub-content-wraps {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.book-left-page {
  width: 80%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  font-size: 1.2rem;
  line-height: 5px;
  max-height: 100%;
  white-space: pre-line;
}

.book-left-page p {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .book-left-page {
    width: 100%;
  }
}

.highlight-div {
  position: absolute;
  background-color: #333333;
  opacity: 0.9;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 230px;
  height: 40px;
}

.dictionary-container {
  border-right: 1px solid rgba(255, 255, 255, 0.685);
  padding: 2px;
  padding-right: 20px;
}

.dictionary-container p {
  color: rgba(255, 255, 255, 0.904) !important;
  font-size: 14px;
  font-weight: 900;
}

.dictionary-container p:hover {
  color: rgb(254, 191, 10) !important;
  cursor: pointer;
}

.language-container {
  padding: 2px;
  padding-left: 20px;
}

.language-container p {
  color: rgba(255, 255, 255, 0.863) !important;
  font-size: 14px;
  font-weight: 900;
}

.language-container p:hover {
  color: rgb(254, 191, 10) !important;
  cursor: pointer;
}

.book-right-page {
  padding: 20px;
  width: 20%;
  box-shadow: -6px 0 12px rgba(0, 0, 0, 0.123);
}

.book-main-image {
  height: 95%;
  width: 90%;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.click-on-dictionary-container {
  position: absolute;
}

.click-on-dictionary {
  position: relative;
  background-color: #333333;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
}

.click-on-dictionary-hd-wrap p {
  color: white;
  font-size: 19px;
  width: 200px;
  margin: 0px;
  margin-top: 20px;
}

.click-on-dictionary-text-wrap {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px;
  overflow-x: hidden;
}

.click-on-dictionary-text-wrap::-webkit-scrollbar {
  width: 10px;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.click-on-dictionary-text-wrap::-webkit-scrollbar-track {
  width: 18px;
  border-radius: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.click-on-dictionary-text-wrap::-webkit-scrollbar-thumb {
  margin-top: 30px;
  margin-bottom: 30px;
}

.click-on-dictionary-text-wrap::-webkit-scrollbar-thumb {
  background-color: #fdc21f;
  border: 1px solid #fdc21f;
  border-radius: 20px;
  width: 5px;
}

.click-on-dictionary-text-wrap p {
  color: white;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 0px;
}

.all-language {
  font-size: 18px;
  text-transform: capitalize;
}

.cross-icon {
  position: absolute;
  top: -11px;
  right: -12px;
  background-color: #febf0b;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cross-icon:hover {
  cursor: pointer;
}

.select-language-container {
  position: absolute;
  font-size: 15px;
}

.select-language {
  position: relative;
  background-color: #333333;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  width: 250px;
}

.select-language-hd-wrap p {
  color: white;
  font-size: 16px;
  margin: 0px;
  padding: 10px;
  font-weight: 700;
}

.select-language-hd-wrap {
  border-bottom: 2px solid rgb(254, 191, 10);
}

.change-languages {
  padding: 10px;
}

.change-languages ul {
  list-style: none;
}

.all-language::before {
  content: "\2022";
  color: #febf0b;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.all-language {
  color: white;
}

.selected-language {
  color: #febf0b;
}

.all-language:hover {
  color: #febf0b;
  cursor: pointer;
}

.cencel-btn-container {
  border-right: 1px solid black;
  padding: 2px;
  padding-right: 20px;
}

.cencel-btn-container p {
  color: black;
  margin: 0px;
}

.cencel-btn-container p:hover {
  color: white;
  cursor: pointer;
}

.done-btn-container {
  padding: 2px;
  padding-left: 20px;
}

.done-btn-container p {
  color: black;
  margin: 0px;
}

.done-btn-container p:hover {
  color: white;
  cursor: pointer;
}

.cancel-done-btn-wrap {
  display: flex;
  justify-content: center;
  border-radius: 20px;
  background-color: #febf0b;
  width: 200px;
  margin: auto;
  margin-bottom: 20px;
}

.english-language-container {
  position: absolute;
}

.english-language {
  position: relative;
  background-color: #333333;
  border-radius: 20px;
  width: 250px;
}

.english-hd-wrap p {
  color: white;
  font-size: 16px;
  margin: 0px;
  padding: 10px;
  font-weight: 700;
}

.english-hd-wrap {
  border-bottom: 2px solid rgb(254, 191, 10);
}

.English-language-text p {
  color: white;
  padding: 10px;
  font-size: 20px;
  margin: 0px;
}

@media (max-width: 790px) {
  audio {
    width: 250px;
  }
}

@media (max-width: 730px) {
  audio {
    width: 200px;
  }
}

@media (max-width: 670px) {
  audio {
    width: 150px;
  }
}

@media (max-width: 615px) {
  audio {
    width: 100px;
  }
}

@media (max-width: 515px) {
  audio {
    width: 250px;
  }
}

@media (max-width: 550px) {
  audio {
    width: 300px;
  }

  .volume-main-wrap {
    margin-right: 10px;
  }

  .icon-volume-wrap {
    position: absolute;
    right: -95px;
    top: -60px;
  }
}

@media (max-width: 470px) {
  audio {
    width: 210px;
  }
}

@media (max-width: 420px) {
  audio {
    width: 180px;
  }
}

@media (max-width: 370px) {
  audio {
    width: 150px;
  }
}

@media (max-width: 350px) {
  audio {
    width: 120px;
  }
}

.loading-text {
  margin-left: 20px;
}

@media only screen and (min-width: 551px) {
  .off-on-without-mobile {
    /* display: none; */
  }
}