
.book-listing .bookImage {
    width: 75px;
    height: 85px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.book-listing .content-wrap {
    display: flex;
    flex-direction: column;
}

.book-listing .heading {
    font-size: 18px;
    color: black;
}

.book-listing .title {
    font-size: 16px;
}

.book-listing .subtitle {
    font-size: 10px;
    color: gray;
}

.profile-book-img {
    width: 75px !important;
    height: 85px !important;
text-align: center;
}

.profile-book-img-container {
    margin-right: 10px;
}
.profile-book-img {
    box-shadow: -1px 3px 7px rgba(0, 0, 0, 0.849) !important;
    margin: 10px !important;

}
.profile-books-read-responsive{
    display: none;
}

@media (max-width:360px){
    .profile-books-read-responsive{
        display: flex;
    }
    /* .profile-books-read-responsive .profile-book-img-container {
        display: flex;
        justify-content: center;
    } */
    .profile-books-read-wrap{
        display: none;
    }
}