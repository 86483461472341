.switch {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 24px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: darkgray;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    top: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #fde71f;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #fde71f;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .registration-genres-choice{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  .profile-img{
    max-width: 90px;
    min-width: 90px;
  }
  .profile-img-wrap{
    width: 150px !important;
    flex-wrap: wrap !important;
    padding: 10px !important;
    margin: 0px !important;

  }