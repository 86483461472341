.faqs-main-container{
  width: 100%;
  height: 100%;
  min-height: 100vh;
  box-shadow: 0px 2px 35px -5px rgba(0, 0, 0, 0.5),
  0px 2px 99px -5px rgba(0, 0, 0, 0.5);
  background-image: url(../../../assets/muti-lingual/BG.png);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.faqs-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto auto auto auto;
  padding-bottom: 20px;
}
.faqs-content-container {
  width: 40%;
  margin: 0px auto 0px auto;
}
.faqs-hd-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}
.faqs-content-wrap {
  padding-top: 0.8rem;
}
.faqs-hd {
  color: white;
  font-size: 3.2vw;
  font-weight: 900;
}
.faqs-content-hd {
  font-size: 1.5vw;
  font-weight: 600;
  margin: 0px;
}
.faqs-text {
  margin: 0px;
  color: white;
  font-size: 1.4vw;
  width: 100%;
  padding: 0; /* Add padding for both active and non-active states */
  /*background-color: whitesmoke;*/
  margin-top: 2px;
  border-radius: 10px;
  max-height: 0;
  overflow: hidden;
  opacity: 0; /* Set initial opacity to 0 */
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out,
    opacity 0.3s ease-in-out; /* Apply transition to max-height, padding, and opacity */
}

.faqs-text.active {
  max-height: 200px;
  padding: 10px; /* Keep padding consistent */
  opacity: 1; /* Set opacity to 1 when active */
}

.faqs-content-hd-icon-wrap {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFC727;
  border-radius: 10px;
  padding: 10px;
}
.faqs-icons {
  font-size: 18px;
  cursor: pointer;
  color: white;
}
.faqs-img-container {
  width: 50%;
  margin: 0px auto 0px auto;
}
.faqs-img{
  width: 100%;
  height: 75vh;
  border-radius: 20px;
}
.faqs-btn-wrap{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

@media (max-width: 1024px) {
  .faqs-container {
    min-height: 600px;
  }
  .faqs-hd {
    font-size: 4vw;
  }
  .faqs-content-hd {
    font-size: 2.2vw;
  }
  .faqs-text {
    font-size: 1.7vw;
  }
}
@media (max-width: 850px) {
  .faqs-container{
    display: flex;
    flex-direction: column;
  }
  .faqs-content-container{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }
  .faqs-img-container{
    width: 90%;
  }
  .faqs-hd {
    font-size: 5vw;
  }
  .faqs-content-hd {
    font-size: 3.2vw;
  }
  .faqs-text {
    font-size: 2.2vw;
  }
}
@media (max-width: 550px) {
  .faqs-hd {
    font-size: 6vw;
  }
  .faqs-content-hd {
    font-size: 4.2vw;
  }
  .faqs-text {
    font-size: 3vw;
  }
  .faqs-img{
    height: 50vh;
  }
}
